body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}
* {
  box-sizing: border-box;
}