html, body {
  height: 100%;
  font-family: "Open Sans", sans-serif;
  background-color: #7F7F7F;
  color: #eee;
}

h1, h2, h3, h4, h5, p, a {
  margin: 0;
  padding: 0;
}

a, .leaflet-container a {
  font-weight: bold;
  text-decoration: none;
  color: #002640;
  transition: color 0.2s ease-out;
}
a:hover, .leaflet-container a:hover {
  color: #eee;
}

.jb-hidden {
  display: none;
}

.jb-row {
  display: flex;
  flex-wrap: wrap;
}

.jb-column {
  display: flex;
  flex-direction: column;
}

.jb-center {
  text-align: center;
  margin: auto;
}

.jb-fill {
  height: 100%;
  flex: 1;
}

.jb-content {
  max-width: 1200px;
  font-size: 0.9em;
  padding: 3em 0;
}

.jb-btn {
  color: #7F7F7F;
  background-color: #002640;
  border-radius: 4px;
  border: 2px solid #7F7F7F;
  padding: 0.5em;
}

header {
  width: 100%;
  background-color: #002640;
  color: #7F7F7F;
}
header .jb-row {
  margin: 0 auto;
  max-width: 1440px;
  align-items: center;
  flex-wrap: nowrap;
}
header .jb-row .jb-logo {
  display: flex;
  padding: 0.5em;
  align-items: center;
  color: #eee;
}
header .jb-row .jb-logo .fa-5x {
  font-size: 2em;
}
header .jb-row .jb-logo .jb-title {
  font-size: 1em;
  padding: 0 0 0 0.5em;
}
header .jb-row .jb-nav-wrapper {
  flex: 1;
  display: flex;
  text-align: right;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5em;
  position: relative;
}
header .jb-row .jb-nav-wrapper form {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
header .jb-row .jb-nav-wrapper form div {
  color: #adadad;
  background: #eee;
  padding: 0.25em;
}
header .jb-row .jb-nav-wrapper form div:focus-within {
  outline: -webkit-focus-ring-color auto 5px;
}
header .jb-row .jb-nav-wrapper form div i, header .jb-row .jb-nav-wrapper form div input.jb-search {
  background: transparent;
  padding: 1px;
  font-size: 0.7em;
}
header .jb-row .jb-nav-wrapper form div input.jb-search {
  font-family: inherit;
  border: none;
}
header .jb-row .jb-nav-wrapper form div input.jb-search:focus {
  outline: none;
}
header .jb-row .jb-nav-wrapper button {
  display: none;
  margin: 0.5em;
  padding: 1em;
}
header .jb-row .jb-nav-wrapper nav.jb-nav {
  display: none;
  flex-wrap: wrap;
  position: absolute;
  top: 65px;
  right: 20px;
  z-index: 1004;
}
header .jb-row .jb-nav-wrapper nav.jb-nav a {
  flex: 0 0 100%;
  text-align: center;
  color: #eee;
}
header .jb-row .jb-nav-wrapper nav.jb-nav a:hover {
  color: #7F7F7F;
}
header .jb-row .jb-nav-wrapper nav.jb-nav a:first-of-type {
  border-bottom: none;
}

main .jb-background {
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}
main .jb-banner {
  color: #002640;
  border-right: none;
  border-left: none;
  background-color: #adadad;
  padding: 5px;
}
main .jb-banner .jb-top {
  width: 100%;
  justify-content: center;
  font-size: 2em;
}
main .jb-banner .jb-top .jb-left, main .jb-banner .jb-top .jb-right, main .jb-banner .jb-top.jb-next {
  padding: 1em;
  max-width: 100%;
}
main .jb-banner .jb-top .jb-left {
  flex: 0 0 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1em;
}
main .jb-banner .jb-top .jb-left h1, main .jb-banner .jb-top .jb-left h2, main .jb-banner .jb-top .jb-left h5 {
  font-weight: normal;
}
main .jb-banner .jb-top .jb-right {
  flex: 0 0 400px;
  color: #002640;
  animation: rotate 3s ease-in-out infinite alternate paused;
}
main .jb-banner .jb-top .jb-right:hover {
  animation-play-state: running;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(720deg);
    color: #adadad;
  }
}
main .jb-banner .jb-quote {
  font-size: 1.5em;
  max-width: 768px;
  padding: 1em 0;
}
main .jb-banner .jb-quote p:first-child {
  font-style: italic;
  padding: 1em 1em 0;
}
main .jb-banner .jb-quote p:nth-child(2) {
  padding: 1em;
  text-align: right;
}
main .jb-icon {
  padding: 2em 0;
  max-width: 300px;
}
main .jb-icon h4 {
  padding: 0.5em;
}
main .jb-message {
  position: fixed;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1003;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  text-align: center;
}
main .jb-message h2 {
  color: #002640;
  background-color: rgba(0, 0, 0, 0.7);
  max-width: 576px;
  margin: 0 auto;
}
main .jb-message i.fa-spinner {
  color: #002640;
  animation: spin 1.5s ease-out infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
main section.jb-fill {
  flex: 1;
}
main section.jb-fill.jb-hidden {
  display: none;
}
main section.jb-fill.jb-hidden ~ aside {
  flex: 100%;
  margin: 0;
  display: block;
}
main aside {
  display: none;
  z-index: 1001;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 0.8em;
  background-color: #7F7F7F;
  max-height: 100%;
}
main aside table {
  width: 100%;
  color: #eee;
  background-color: #002640;
  border-collapse: collapse;
  text-align: left;
}
main aside table tr {
  cursor: pointer;
}
main aside table th, main aside table td {
  padding: 5px;
  border-top: 1px solid gray;
}
main aside table thead th {
  border-bottom: 2px solid gray;
}
main aside table tbody tr:hover, main aside table tbody tr.jb-selected {
  background-color: #555;
}
main .jb-controls {
  position: absolute;
  right: 0;
  z-index: 1002;
}
main .jb-controls .jb-btn {
  margin: 0.5em;
  text-align: center;
}
main.jb-main {
  overflow: hidden;
}
main #map {
  color: black;
}
main #map ~ .jb-popup {
  display: none;
}
main #map .leaflet-popup-content-wrapper, main #map .leaflet-popup-tip {
  color: #eee;
  background: #002640;
}
main #map .leaflet-popup-content-wrapper .leaflet-popup-content, main #map .leaflet-popup-tip .leaflet-popup-content {
  padding: 0.5em;
}
main #map .leaflet-popup-content-wrapper .leaflet-popup-content p, main #map .leaflet-popup-tip .leaflet-popup-content p {
  margin: 0;
  color: #adadad;
}
main #map .leaflet-popup-content-wrapper a, main #map .leaflet-popup-tip a {
  color: #eee;
}
main #map .leaflet-popup-content-wrapper a:hover, main #map .leaflet-popup-tip a:hover {
  color: #7F7F7F;
}
main #map div.jb-map-icon i {
  color: #002640;
  text-shadow: 0px 0px 15px #adadad;
}
main #map .marker-cluster-small, main #map .marker-cluster-large, main #map .marker-cluster-medium {
  background-color: rgba(0, 38, 64, 0.6);
}
main #map .marker-cluster-small div, main #map .marker-cluster-large div, main #map .marker-cluster-medium div {
  background-color: rgb(0, 38, 64);
  color: #eee;
}
main section.jb-main {
  padding: 0.5em;
  margin: 0 auto;
}
main section.jb-main h1, main section.jb-main h4, main section.jb-main h5, main section.jb-main div.jb-html, main section.jb-main .jb-about {
  padding: 3em 0 0;
}
main section.jb-main.jb-detail h1, main section.jb-main.jb-detail h4, main section.jb-main.jb-detail h5, main section.jb-main.jb-detail div.jb-html {
  background-color: #002640;
  padding: 20px;
  color: #adadad;
}
main section.jb-main.jb-detail h1 a, main section.jb-main.jb-detail h4 a, main section.jb-main.jb-detail h5 a, main section.jb-main.jb-detail div.jb-html a {
  color: #eee;
  padding: 0;
}
main section.jb-main.jb-detail h1 a:hover, main section.jb-main.jb-detail h4 a:hover, main section.jb-main.jb-detail h5 a:hover, main section.jb-main.jb-detail div.jb-html a:hover {
  color: #7F7F7F;
}
main section.jb-main.jb-detail div.jb-html {
  min-height: 50vh;
}

footer {
  padding: 2em;
  width: 100%;
}
footer div {
  flex: 1;
}

@media (min-width: 576px) {
  header .jb-row .jb-nav-wrapper button, header .jb-row .jb-nav-wrapper nav {
    display: flex;
  }
  header .jb-row .jb-nav-wrapper button.jb-nav-visible, header .jb-row .jb-nav-wrapper nav.jb-nav-visible {
    display: flex;
  }

  main .jb-banner {
    padding: 1em;
  }
  main .jb-banner .jb-top {
    padding: 1em;
  }
  main .jb-banner .jb-top .jb-left, main .jb-banner .jb-top .jb-right, main .jb-banner .jb-top.jb-next {
    padding: 1em;
  }
  main .jb-banner .jb-top .jb-right {
    font-size: 2em;
  }
  main .jb-banner .jb-icon {
    padding: 2em 1em;
  }
}
@media (min-width: 768px) {
  header .jb-row .jb-logo .fa-5x {
    font-size: 3em;
  }
  header .jb-row .jb-logo .jb-title {
    font-size: 2em;
  }
  header .jb-row .jb-nav-wrapper button {
    display: none;
  }
  header .jb-row .jb-nav-wrapper nav.jb-nav, header .jb-row .jb-nav-wrapper nav.jb-nav-visible {
    top: 0;
    right: 0;
    display: block;
    position: relative;
  }
  header .jb-row .jb-nav-wrapper nav.jb-nav a, header .jb-row .jb-nav-wrapper nav.jb-nav-visible a {
    font-size: 1.5em;
    border: none;
    display: initial;
  }
  header .jb-row .jb-nav-wrapper form {
    margin: 0.5em;
  }
  header .jb-row .jb-nav-wrapper form div i, header .jb-row .jb-nav-wrapper form div input.jb-search {
    font-size: 1.2em;
  }

  main .jb-banner .jb-top.jb-next {
    padding-bottom: 3em;
  }
}
@media (min-width: 992px) {
  main.jb-main {
    width: 100%;
    margin: 0.5em auto;
    max-width: 1440px;
  }
  main.jb-main .jb-message {
    margin-top: -0.5em;
  }
}
@media (min-width: 1200px) {
  main.jb-main .jb-controls {
    right: 380px;
  }
  main.jb-main .jb-controls .jb-hide-control {
    display: none;
  }
  main.jb-main section.jb-fill, main.jb-main section.jb-fill.jb-hidden {
    display: inherit;
  }
  main.jb-main section.jb-fill ~ aside, main.jb-main section.jb-fill.jb-hidden ~ aside {
    flex: 0 0 380px;
    margin-left: 0.5em;
    display: block;
  }
}
@media (min-width: 1440px) {
  main.jb-main .jb-controls {
    right: 0;
  }
}
@media only screen and (max-device-width: 1440px) {
  main #map .leaflet-google-mutant {
    z-index: inherit !important;
  }
  main #map .leaflet-google-mutant .gm-style {
    z-index: inherit !important;
  }
  main .jb-background {
    background-attachment: scroll;
  }
}

